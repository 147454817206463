import React, { FunctionComponent } from "react"
import { ReviewDownload } from "./Review/reviewDownload"
import { ProductPage } from "./product"

export const EcProductPage: FunctionComponent = () => {
  return (
    <>
      <ProductPage />
    </>
  )
}
