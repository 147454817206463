import firebase from "gatsby-plugin-firebase"
import { ICSVHeader, IRegisterResponse } from "./medicalDao"
import moment from "moment"


export interface IExportJmb {
  mileage_no: string
  name_alpha: string
  blank: string
  date: string
  point: string
  id: string
}

export const JmbExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "Member number", key: "mileage_no", comment: "JMBお得意様番号" },
  { type: "string", label: "ローマ（カナ）字氏名", key: "name_alpha", comment: "ご利用者様氏名" },
  { type: "string", label: "ブランク", key: "blank", comment: "" },
  { type: "string", label: "Use Date", key: "date", comment: "予約日" },
  { type: "string", label: "Accumrated Mileage", key: "point", comment: "付与ポイント（500固定)" },
  { type: "string", label: "Remarks", key: "id", comment: "SOKUYAKU　予約ID" },
]

interface IUserData {
  id: string
  data:firebase.firestore.DocumentData
}

export const getJmbInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined,dateFrom:Date | null,dateTo:Date | null,setProgressNum:any) => {
  let retVal: IExportJmb[] = []
  try {
    console.log(dateFrom)
    console.log(moment(dateFrom).valueOf())
    console.log(dateTo)
    console.log(moment(dateTo).valueOf())
    if (firestore) {
      setProgressNum(0)
      const usersSnapShot = await firestore.collection("private").where("mileage_no",'<', '\uf8ff').where("type",'==', 7).get()
      const reserves = await firestore.collection("reserve")
        .where("date",'>=', moment(dateFrom).valueOf())
        .where("date",'<=', moment(dateTo).valueOf())
        .where("status",'==', 90).get()
      setProgressNum(5)

      const users : IUserData[] = usersSnapShot.docs.map(doc=> ({
        id:doc.id,
        data:doc.data()
      }))

      let i = 0
      const reserves_count = reserves.size
      reserves.forEach(reserve => {
        i++
        const reserve_data = reserve.data()
        let user_data : any = users.find(user => user.id == reserve_data.patient_id)
        if(user_data){
          retVal.push({
            mileage_no: user_data.data.mileage_no,
            name_alpha: `${user_data.data.firstname_alpha} ${user_data.data.lastname_alpha}`,
            blank: "",
            date: reserve_data.date ?　moment(reserve_data.date).format("YYYYMMDD") : "",
            point: "500",
            id: reserve.id,
          })
        }
        setProgressNum(1 + Math.round((i/reserves_count) * 94))
      })
      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}
