import React from "react"
import {Router} from "@reach/router"
import {Layout} from "../components/Layout";
import {MedicalPage} from "../components/medical";
import {UserPage} from "../components/user";
import {PrivateRoute} from "../components/Common/privateRoute";
import {Login} from "../components/login";
import {MedicalDepartmentPage} from "../components/medicalDepartment";
import {UserDownloadPage} from "../components/userDownload";
import {JmbDownloadPage} from "../components/jmbDownload";
import {ReviewPage} from "../components/review";
import {EcProductPage} from "../components/ec_product";
import {ReserveDownloadPage} from "../components/reserveDownload";
import {EcProductPricePage} from "../components/ec_product_price";
import {OrderDownloadPage} from "../components/orderDownload";
import {PudoB2CloudPage} from "../components/pudo_b2cloud";
import {CouponReserveDownloadPage} from "../components/couponReserveDownload";
import {PointAwardDownloadPage} from "../components/pointAwardDownload";
import {DoctorDownloadPage} from "../components/doctorDownload";

const App = () => (
    <Layout>
        <Router basepath={"/app"}>
            <Login path={"/login"}/>
            <PrivateRoute Component={MedicalPage} path={"/medical"}/>
            <PrivateRoute Component={UserPage} path={"/user"}/>
            <PrivateRoute Component={MedicalDepartmentPage} path={"/medical_department"}/>
            <PrivateRoute Component={UserDownloadPage} path={"/user_download"}/>
            <PrivateRoute Component={JmbDownloadPage} path={"/jal_mileage"}/>
            <PrivateRoute Component={ReviewPage} path={"/review"}/>
            <PrivateRoute Component={EcProductPage} path={"/ec_product"}/>
            <PrivateRoute Component={PudoB2CloudPage} path={"/pudo_b2cloud"}/>
            <PrivateRoute Component={CouponReserveDownloadPage} path={"/coupon_reserve"}/>
            <PrivateRoute Component={PointAwardDownloadPage} path={"/point_award"}/>
            <PrivateRoute Component={DoctorDownloadPage} path={"/doctor"}/>
            <PrivateRoute Component={ReserveDownloadPage} path={"/reserve"}/>
            <PrivateRoute Component={EcProductPricePage} path={"/ec_product_price"}/>
            <PrivateRoute Component={OrderDownloadPage} path={"/order"}/>
        </Router>
    </Layout>
)

export default App
