import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { UserCSVHeader } from "../../utils/userDao"

export const UserTemplateDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<any[]>([
    {
      enable: false,
      type: 1,
      email: "clinic_admin@test.com",
      password: "password1",
      name: "クリニック　管理者",
      name_kana: "クリニック　カンリシャ",
      gender: 1,
      tel: "09012345678",
      medical_name: "田中クリニック",
    },
    {
      enable: false,
      type: 2,
      email: "test@test.com",
      password: "password1",
      name: "田中　太郎",
      name_kana: "タナカ　タロウ",
      gender: 1,
      tel: "09012345678",
      medical_name: "田中クリニック",
      department: {
        name: "内科",
        description: "主に生活習慣病に関する診療を行います",
        in_hospital_prescription: true
      },
      description: "とても良い治療をします",
      mon: {
        work: true,
        start: 15,
        end: 30
      },
      tue: {
        work: true,
        start: 15,
        end: 30
      },
      wed: {
        work: true,
        start: 15,
        end: 30
      },
      thu: {
        work: true,
        start: 15,
        end: 30
      },
      fri: {
        work: true,
        start: 15,
        end: 30
      },
      sat: {
        work: false,
        start: 15,
        end: 30
      },
      sun: {
        work: false,
        start: 15,
        end: 30
      },
    },
    {
      enable: false,
      type: 3,
      email: "clinic_desk@test.com",
      password: "password1",
      name: "クリニック　受付",
      name_kana: "クリニック　受付",
      gender: 1,
      tel: "09012345678",
      medical_name: "田中クリニック",
    },
    {
      enable: false,
      type: 4,
      email: "pharmacy_admin@test.com",
      password: "password1",
      name: "薬局　管理者",
      name_kana: "ヤッキョク　カンリシャ",
      gender: 1,
      tel: "09012345678",
      medical_name: "山田薬局",
    },
    {
      enable: false,
      type: 5,
      email: "test2@test.com",
      password: "password1",
      name: "山田　次郎",
      name_kana: "ヤマダ　ジロウ",
      gender: 2,
      tel: "09012345678",
      medical_name: "山田薬局",
      description: "迅速にお薬を処方します",
      mon: {
        work: false,
        start: 0,
        end: 47
      },
      tue: {
        work: false,
        start: 0,
        end: 47
      },
      wed: {
        work: true,
        start: 15,
        end: 30
      },
      thu: {
        work: true,
        start: 15,
        end: 30
      },
      fri: {
        work: false,
        start: 0,
        end: 47
      },
      sat: {
        work: false,
        start: 15,
        end: 30
      },
      sun: {
        work: false,
        start: 15,
        end: 30
      },
    },
    {
      enable: false,
      type: 6,
      email: "pharmacy_desk@test.com",
      password: "password1",
      name: "薬局　受付",
      name_kana: "ヤッキョク　ウケツケ",
      gender: 1,
      tel: "09012345678",
      medical_name: "山田薬局",
    },
  ])
  const [ done, setDone ] = useState<boolean>(false)

  const renderTable = () => {
    return UserCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current) {
      setDone(true)
    }
  }, [ clink ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
      setDone(false)
    }
  }, [ done ])

  return (
    <>
      <Box style={{ display:"flex", justifyContent: "flex-end" }}>
        <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>テンプレートをダウンロード</Button>
      </Box>

      <Box className={classes.verticalMargin}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant={"subtitle1"}>
              各列の詳細を確認する
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color={"error"} variant={"caption"}>
                        数値は必ず半角でご入力下さい。また、空とはテキストエディタでCSVを開いた際に「""」の状態を示します。
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {renderTable()}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box style={{ display: "hidden" }}>
        <CSVLink ref={clink} data={data} headers={UserCSVHeader} filename={"user_template.csv"} />
      </Box>
    </>
  )
}
