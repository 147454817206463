import React, { FunctionComponent } from "react"
import { DepartmentDownload } from "./Medical/departmentDownload"

export const MedicalDepartmentPage: FunctionComponent = () => {
  return (
    <>
      <DepartmentDownload />
    </>
  )
}
