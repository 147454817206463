import React from "react"
import { ICSVHeader, MedicalCSVHeader } from "../../utils/medicalDao"
import { Divider, Paper, Table, TableContainer, Typography } from "@material-ui/core"
import { CsvHeader } from "./csvHeader"
import { CsvRow } from "./csvRow"
import { useStyles } from "../../styles/useStyle"

export interface ICSVViewProps {
  header: ICSVHeader[]
  data: any[]
}

export const CSVView = React.memo((props: ICSVViewProps) => {
  const classes = useStyles()
  const { data, header } = props
  let retVal = null
  if (data.length) {
    retVal = (
      <Paper className={classes.paperContainer}>
        <Typography component={"h3"} variant={"h5"}>CSV情報</Typography>
        <Divider />
        <TableContainer className={classes.verticalMargin}>
          <Table size={"small"}>
            <CsvHeader header={header} />
            <CsvRow header={header} data={data} />
          </Table>
        </TableContainer>
      </Paper>
    )
  }

  return retVal
})
