import { useEffect, useState } from "react"
import firebase from "gatsby-plugin-firebase"

const settings = {
  "USE_FUNCTIONS_EMULATOR": "FALSE",
  "FUNCTIONS_EMULATOR_IP": "192.168.0.139",
  "FUNCTIONS_EMULATOR_PORT": "5001"
}

export const useFirebaseFunction = () => {
  const [ functions, setFunctions ] = useState<firebase.functions.Functions>()
  useEffect(() => {
    const FF = firebase.app().functions("asia-northeast1")

    if(settings.USE_FUNCTIONS_EMULATOR === "TRUE"){
      FF.useFunctionsEmulator("http://" + settings.FUNCTIONS_EMULATOR_IP + ':' + settings.FUNCTIONS_EMULATOR_PORT)
    }

    setFunctions(FF)

  }, [ firebase ])

  return functions
}
