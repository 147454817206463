import firebase from "gatsby-plugin-firebase"
import { useEffect, useState } from "react"

export const useFirestore = () => {
  const [ firestore, setFirestore ] = useState<firebase.firestore.Firestore | undefined>(undefined)

  useEffect(() => {
    const temp = firebase.firestore()
    temp.settings({ ignoreUndefinedProperties: true })
    setFirestore(temp)
  }, [ firebase ])

  return firestore
}
/**
 * 表示時間
 */
export const DISPLAY_TIME = {
  "00:00": 0, "00:30": 1,
  "01:00": 2, "01:30": 3,
  "02:00": 4, "02:30": 5,
  "03:00": 6, "03:30": 7,
  "04:00": 8, "04:30": 9,
  "05:00": 10, "05:30": 11,
  "06:00": 12, "06:30": 13,
  "07:00": 14, "07:30": 15,
  "08:00": 16, "08:30": 17,
  "09:00": 18, "09:30": 19,
  "10:00": 20, "10:30": 21,
  "11:00": 22, "11:30": 23,
  "12:00": 24, "12:30": 25,
  "13:00": 26, "13:30": 27,
  "14:00": 28, "14:30": 29,
  "15:00": 30, "15:30": 31,
  "16:00": 32, "16:30": 33,
  "17:00": 34, "17:30": 35,
  "18:00": 36, "18:30": 37,
  "19:00": 38, "19:30": 39,
  "20:00": 40, "20:30": 41,
  "21:00": 42, "21:30": 43,
  "22:00": 44, "22:30": 45,
  "23:00": 46, "23:30": 47,
  "24:00": 48
} as const
export type DisplayTimeType = typeof DISPLAY_TIME[keyof typeof DISPLAY_TIME]
export const DisplayTimeKey = (value: DisplayTimeType | number) => {
// @ts-ignore
  return Object.keys(DISPLAY_TIME).find(key => DISPLAY_TIME[key] === value) ?? ""
}
