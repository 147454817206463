import React, { FunctionComponent } from "react"
import { UserDownload } from "./User/userDownload"

export const UserDownloadPage: FunctionComponent = () => {
  return (
    <>
      <UserDownload />
    </>
  )
}
