import firebase from "gatsby-plugin-firebase"
import { ICSVHeader, IRegisterResponse } from "./medicalDao"
import moment from "moment"
import geohash from "ngeohash"
import * as nGram from "n-gram"
import dot from "dot-object"
import lodash from "lodash"


export interface IExportProduct {
  enable: string,
  corp_id: string,
  price: string,
  id : string,
  cat1 : string,
  cat2 : string,
  name : string,
  main_image? : string,
  main_image_thumbnail? : string,
  sub_images? : string[],
  mkr_name : string,
  quantity_value : string,
  quantity_unit : string,
  per_product_value : string,
  per_product_unit : string,
  medicine_category : string,
  description : string,
}

export interface IProductCSV {
  enable: string,
  corp_id: string,
  price: string,
  id : string,
  cat1 : string,
  cat2 : string,
  name : string,
  main_image? : string,
  main_image_thumbnail? : string,
  sub_images? : string[],
  mkr_name : string,
  quantity_value : string,
  quantity_unit : string,
  per_product_value : string,
  per_product_unit : string,
  medicine_category : string,
  description : string,
}

export const ProductExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "取り込み対象", key: "enable", comment: "id" },
  { type: "string", label: "法人名", key: "corp_id", comment: "cat1" },
  { type: "string", label: "商品価格", key: "price", comment: "price" },
  { type: "string", label: "JAN", key: "id", comment: "id" },
  { type: "string", label: "大分類", key: "cat1", comment: "cat1" },
  { type: "string", label: "中分類", key: "cat2", comment: "cat2" },
  { type: "string", label: "商品名", key: "name", comment: "name" },
  // { type: "string", label: "main_image", key: "main_image", comment: "main_image" },
  // { type: "string", label: "main_image_thumbnail", key: "main_image_thumbnail", comment: "main_image_thumbnail" },
  // { type: "string", label: "sub_images", key: "sub_images", comment: "sub_images" },
  { type: "string", label: "メーカー", key: "mkr_name", comment: "mkr_name" },
  { type: "string", label: "内容量", key: "quantity_value", comment: "quantity_value" },
  { type: "string", label: "内容量単位", key: "quantity_unit", comment: "quantity_unit" },
  { type: "string", label: "入数", key: "per_product_value", comment: "per_product_value" },
  { type: "string", label: "入数単位", key: "per_product_unit", comment: "per_product_unit" },
  { type: "string", label: "医薬品区分", key: "medicine_category", comment: "medicine_category" },
  { type: "string", label: "description", key: "description", comment: "description" },
]

export const ProductCSVHeader: ICSVHeader[] = [
  { type: "boolean", label: "取り込み対象", key: "enable", comment: "「true」：新規登録されます、空：行をスキップして登録されません" },
  { type: "string", label: "法人名", key: "corp_id", comment: "登録先の法人名を入力します" },
  { type: "string", label: "商品価格", key: "price", comment: "price" },
  { type: "string", label: "JAN", key: "id", comment: "対象商品ＪＡＮコード。変更禁止。" },
  { type: "string", label: "大分類", key: "cat1", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "中分類", key: "cat2", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "商品名", key: "name", comment: "情報参照用データ。登録には反映されません。" },
  // { type: "string", label: "main_image", key: "main_image", comment: "情報参照用データ。登録には反映されません。" },
  // { type: "string", label: "main_image_thumbnail", key: "main_image_thumbnail", comment: "情報参照用データ。登録には反映されません。" },
  // { type: "string", label: "sub_images", key: "sub_images", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "メーカー", key: "mkr_name", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "内容量", key: "quantity_value", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "内容量単位", key: "quantity_unit", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "入数", key: "per_product_value", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "入数単位", key: "per_product_unit", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "医薬品区分", key: "medicine_category", comment: "情報参照用データ。登録には反映されません。" },
  { type: "string", label: "description", key: "description", comment: "description" },
]

interface IProductData {
  id: string
  data:firebase.firestore.DocumentData
}

interface IProductCategoryData {
  id: string
  data:firebase.firestore.DocumentData
}

export const convertProductCSVToJSON = (data: string[]) => {
  let retVal = {}
  data.forEach((value, index) => {
    const key = ProductCSVHeader[index].key
    let tempVal = undefined
    if (ProductCSVHeader[index].type === "boolean") {
      // 強制的にBoolean変換
      tempVal = !!value
    } else {
      tempVal = value.replace("'","")
    }

    const obj = dot.object({ [key]: tempVal })
    lodash.merge(retVal, obj)
  })

  return retVal
}

export const getProductInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined, setProgressNum:any) => {
  let retVal: IExportProduct[] = []
  try {
    if (firestore) {
      setProgressNum(0)
      const productsSnapShot = await firestore.collection("product").get()
      const productCategorySnapShot = await firestore.collection("product_category").get()
      setProgressNum(5)

      const products : IProductData[] = productsSnapShot.docs.map(doc=> ({
        id:doc.id,
        data:doc.data()
      }))

      const productCategory : IProductCategoryData[] = productCategorySnapShot.docs.map(doc=> ({
        id:doc.id,
        data:doc.data()
      }))

      let i = 0
      const product_count = products.length
      productsSnapShot.docs.forEach(product => {

        i++
        const product_data = product.data()
        const category = productCategory.find(v => v.data.cat2 == product_data.cat2)
        if(product_data){
          retVal.push({
            enable: "",
            corp_id: "",
            price: "",
            id: "'" + product.id,
            name: product_data.name,
            cat1: category?.data?.cat1_name ? category?.data?.cat1_name : "",
            cat2: category?.data?.cat2_name ? category?.data?.cat2_name : "",
            medicine_category: product_data.medicine_category,
            mkr_name: product_data.mkr_name,
            per_product_unit: product_data.per_product_unit,
            per_product_value: product_data.per_product_value,
            // main_image: product_data.main_image,
            // main_image_thumbnail: product_data.main_image_thumbnail,
            quantity_unit: product_data.quantity_unit,
            quantity_value: product_data.quantity_value,
            description: product_data.description,
          })
        }
        setProgressNum(1 + Math.round((i/product_count) * 94))
      })
      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

export const registerProduct = (firestore:firebase.firestore.Firestore | undefined , data: Array<IProductCSV>) => {
  if (firestore) {
    const promiseArray = data.map((value, index) => {
      return async (): Promise<IRegisterResponse> => {
        let result = ""
        //正常
        if (value && value.enable && value.id) {
          try {
            await firestore.collection(`ec_corporation/${value.corp_id}/product`).doc(value.id).set({
              id: value.id,
              price: Number(value.price)
            },{merge: true})
            result = "登録が完了しました"
          } catch (e) {
            console.log(e)
            result = "不明なエラーが発生しました。"
          }
        } else {
          result = "スキップされました"
        }

        return ({
          index: index + 2,
          result
        })
      }
    })

    const arrayResult: IRegisterResponse[] = []
    return promiseArray.reduce((prev, current) => {
      return prev.then(value => {
        arrayResult.push(value)
        return current()
      })
    }, Promise.resolve({
      index: 1,
      result: "先頭行はスキップされます"
    })).then((result) => {
      arrayResult.push(result)
      return arrayResult
    })
  } else {
    const temp: IRegisterResponse = {
      index: 0,
      result: "接続が中断されました。時間をおいてお試しください"
    }
    return Promise.resolve([temp])
  }
}

export const getProductPriceInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined, setProgressNum:any, id : string) => {
  let retVal: IExportProduct[] = []
  console.log("id",id)
  try {
    if (firestore) {
      setProgressNum(0)
      const productsSnapShot = await firestore.collection(`ec_corporation/${id}/product`).get()
      const productCategorySnapShot = await firestore.collection("product_category").get()
      setProgressNum(5)

      const products : IProductData[] = productsSnapShot.docs.map(doc=> ({
        id:doc.id,
        data:doc.data()
      }))

      const productCategory : IProductCategoryData[] = productCategorySnapShot.docs.map(doc=> ({
        id:doc.id,
        data:doc.data()
      }))

      let i = 0
      const product_count = products.length
      productsSnapShot.docs.forEach(product => {

        i++
        const product_data = product.data()
        const category = productCategory.find(v => v.data.cat2 == product_data.cat2)
        if(product_data){
          retVal.push({
            enable: "",
            corp_id: id,
            price: product_data.price ?? "",
            id: "'" + product.id,
            name: product_data.name,
            cat1: category?.data?.cat1_name ? category?.data?.cat1_name : "",
            cat2: category?.data?.cat2_name ? category?.data?.cat2_name : "",
            medicine_category: product_data.medicine_category,
            mkr_name: product_data.mkr_name,
            per_product_unit: product_data.per_product_unit,
            per_product_value: product_data.per_product_value,
            // main_image: product_data.main_image,
            // main_image_thumbnail: product_data.main_image_thumbnail,
            quantity_unit: product_data.quantity_unit,
            quantity_value: product_data.quantity_value,
            description: product_data.description,
          })
        }
        setProgressNum(1 + Math.round((i/product_count) * 94))
      })
      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}
