import React from "react"
import { ICSVHeader, RESERVE_TIME } from "../../utils/medicalDao"
import { TableBody, TableCell, TableRow } from "@material-ui/core"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import ClearIcon from "@material-ui/icons/Clear"
import { useStyles } from "../../styles/useStyle"
import dot from "dot-object"

export interface ICSVRowProps {
  header: ICSVHeader[]
  data: any[]
}

export const CsvRow = React.memo((props: ICSVRowProps) => {
  const classes = useStyles()
  const { data, header } = props
  let retVal = null
  if (data.length) {
    retVal = data.map((row, index) => {
      try {
        let style = null
        if (row["enable"]) {
          style = classes.enableRow
        } else {
          style = classes.disableRow
        }
        const cells = header.map((header, idx) => {
          // @ts-ignore
          const cell = dot.pick(header.key, row)
          if (cell !== undefined) {
            // @ts-ignore
            if (header.type === "boolean") {
              return <TableCell className={classes.tableCellStyle} key={`csv_data_${index}_${idx}`}>{cell ?
                <RadioButtonUncheckedIcon color={"primary"} /> : <ClearIcon color={"error"} />}</TableCell>
            } else {
              let content = null
              switch(header.key) {
                case "type":
                  switch (cell) {
                    case 1:
                      content = "クリニック管理者"
                      break
                    case 2:
                      content = "医師"
                      break
                    case 3:
                      content = "クリニック受付"
                      break
                    case 4:
                      content = "薬局管理者"
                      break
                    case 5:
                      content = "薬剤師"
                      break
                    case 6:
                      content = "薬局受付"
                      break
                    default:
                      break
                  }
                  break
                case "gender":
                  content = cell === 1 ? "男性" : "女性"
                  break
                case "bank_account_type":
                  content = cell ? cell === 1 ? "普通" : "当座" : ""
                  break
                default:
                  content = cell
                  if (header.key.indexOf("start") != -1) {
                    content = RESERVE_TIME[cell]
                  }
                  if (header.key.indexOf("end") != -1) {
                    content = RESERVE_TIME[cell]
                  }
                  break
              }
              return <TableCell className={classes.tableCellStyle} key={`csv_data_${index}_${idx}`}>{content}</TableCell>
            }
          } else {
            return null
          }
        })

        return (
          <TableRow key={`csv_data_row_${index}`} className={style}>
            <TableCell key={`csv_row_no_${index}`}>{index + 1}</TableCell>
            {cells}
          </TableRow>
        )
      } catch (e) {
        return null
      }
    })
  } else {
    retVal = null
  }

  return (
    <TableBody>
      {retVal}
    </TableBody>
  )
})
