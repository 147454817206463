import React, { FunctionComponent } from "react"
import { ReserveDownload } from "./Reserve/reserveDownload"

export const ReserveDownloadPage: FunctionComponent = () => {
  return (
    <>
      <ReserveDownload />
    </>
  )
}
