import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Backdrop,
  Box,
  Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { useFirestore } from "../../utils/firestore"
import {
  AWARD, AwardType,
  getPointAwardInformationByCSV, IExportPointAward,
  PointAwardExportCSVHeader,
} from "../../utils/userDao"
import { useFirebaseFunction } from "../../utils/functions"
import moment from "moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import jaLocale from "date-fns/locale/ja"

export const PointAwardDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<IExportPointAward[]>([])
  const [ done, setDone ] = useState<boolean>(false)
  const [ dateInfo, setDateInfo ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<boolean>(false);
  const [ progressNum, setProgressNum ] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date())
  const [dateTo, setDateTo] = useState<Date | null>(new Date())
  const [awardType, setAwardType] = useState<AwardType>(AWARD.JAL)
  const firestore = useFirestore()
  const functions = useFirebaseFunction()

  const renderTable = () => {
    return PointAwardExportCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const changeDateFromHandler = (newDate: Date | null): void => {
    setDateFrom(newDate)
  }

  const changeDateToHandler = (newDate: Date | null): void => {
    setDateTo(newDate)
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current && firestore) {

      if(dateInfo){
        if(moment(dateFrom).isAfter(moment(dateTo))) {
          alert("日付指定が間違っています")
          return
        }
      }

      setProgress(true)
      try {
        const result = await getPointAwardInformationByCSV(firestore,functions,dateInfo,dateFrom,dateTo,setProgressNum,awardType)
        setProgress(false)
        setProgressNum(0)
        setData(result)
        setDone(true)
        if(!result.length){
          alert("データ0件")
        }
      }catch (e) {
        setProgress(false)
      }

    }
  }, [ clink, firestore, dateInfo, dateFrom, dateTo, awardType ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
    }
    setDone(false)
  }, [ done ])

  const handleDateChange = (event: any) => {
    setDateInfo(event.target.checked)
  };
  const handleAwardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAwardType(Number(event.target.value) as AwardType);
  };

  return (
    <>
      {progress ?
        <Backdrop open={progress}>
          <CircularProgress variant="determinate" value={progressNum} />
          <Box
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="textPrimary"
            >{`${Math.round(progressNum)}%`}</Typography>
          </Box>
        </Backdrop>
        :
        <>
          <Box style={{ display:"flex", justifyContent: "flex-end", marginBottom: 16 }}>
            <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>ユーザー情報ダウンロード</Button>
          </Box>

          <Box style={{ display:"flex", justifyContent: "flex-end", marginBottom: 16 }}>
            <FormControl component="fieldset">
              <FormLabel component="legend">選択可能なポイント</FormLabel>
              <RadioGroup
                row
                aria-label="award"
                name="award"
                value={awardType}
                onChange={handleAwardChange}
              >
                <FormControlLabel value={AWARD.ALL} control={<Radio />} label="ALL(処理時間：大)" />
                <FormControlLabel value={AWARD.JAL} control={<Radio />} label="JALマイル" />
                <FormControlLabel value={AWARD.PONTA} control={<Radio />} label="Ponta" />
                <FormControlLabel value={AWARD.NANACO} control={<Radio />} label="Nanaco" />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox
                  checked={dateInfo}
                  onChange={handleDateChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              } label="ポイントカード登録日付で絞り込み" />
            </FormGroup>
          </Box>

          {dateInfo ?
            <Box style={{ display:"flex", justifyContent: "flex-end" }}>
              <Typography variant={"subtitle1"} style={{paddingLeft: 20,paddingRight: 20}}>
                ポイント付与日付：
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                <DatePicker
                  value={dateFrom}
                  format="yyyy年M月d日"
                  onChange={changeDateFromHandler}
                />
                <Typography variant={"subtitle1"} style={{paddingLeft: 20,paddingRight: 20}}>
                  ～
                </Typography>
                <DatePicker
                  value={dateTo}
                  format="yyyy年M月d日"
                  onChange={changeDateToHandler}
                />
              </MuiPickersUtilsProvider>
            </Box>
            : undefined}
          <Box className={classes.verticalMargin}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={"subtitle1"}>
                  各列の詳細を確認する
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {renderTable()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box style={{ display: "hidden" }}>
            <CSVLink ref={clink} data={data} headers={PointAwardExportCSVHeader} filename={dateInfo ? `point_award_${moment(dateFrom).format("YYYYMMDD")}-${moment(dateTo).format("YYYYMMDD")}.csv` : "point_award.csv"} />
          </Box>

        </>
      }
    </>
  )
}
