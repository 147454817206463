import { FunctionComponent } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress
} from "@material-ui/core"
import React from "react"
import { useStyles } from "../../styles/useStyle"

export interface IDialogWithLoading {
  title: string
  body: string
  show: boolean
  loading: boolean
  onCancel: () => void
  onExecute: () => void
}

export const DialogWithLoading: FunctionComponent<IDialogWithLoading> = (props) => {
  const classes = useStyles()
  const { title, body, loading, onCancel, onExecute, show } = props

  return (
    <Dialog open={show} onClose={onCancel} disableBackdropClick>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className={classes.wrapMargin}>
          <Button className={classes.horizontalMargin} onClick={onCancel} variant={"outlined"} color={"default"} disabled={loading}>キャンセル</Button>
          <Button onClick={onExecute} variant={"contained"} disableElevation color={"primary"} disabled={loading}>実行</Button>
        </Box>
      </DialogActions>
      {loading? <LinearProgress /> : null}
    </Dialog>
  )
}
