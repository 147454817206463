import React, { FunctionComponent } from "react"
import { JmbDownload } from "./Jmb/userDownload"

export const JmbDownloadPage: FunctionComponent = () => {
  return (
    <>
      <JmbDownload />
    </>
  )
}
