import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import { MedicalCSVHeader } from "../../utils/medicalDao"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"

export const MedicalTemplateDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<any[]>([
    {
      enable: false,
      type: 0,
      name: "test",
      tel: "09012345678",
      fax: "09012345678",
      url: "https://yahoo.com",
      tags: "test1,test2",
      post_code: "105-0011",
      prefecture: "東京都",
      address1: "港区",
      address2: "芝公園4-2-8",
      description: "ここに概要を記入します",
      warning: "ここに注意文言を記入します",
      start: 0,
      end: 12,
      mon: true,
      tue: false,
      wed: true,
      thu: true,
      fri: true,
      sat: false,
      sun: false,
      first_visit: false
    }
  ])
  const [ done, setDone ] = useState<boolean>(false)

  const renderTable = () => {
    return MedicalCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current) {
      setDone(true)
    }
  }, [ clink ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
      setDone(false)
    }
  }, [ done ])

  return (
    <>
      <Box style={{ display:"flex", justifyContent: "flex-end" }}>
        <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>テンプレートをダウンロード</Button>
      </Box>

      <Box className={classes.verticalMargin}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant={"subtitle1"}>
              各列の詳細を確認する
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color={"error"} variant={"caption"}>
                        数値は必ず半角でご入力下さい。また、空とはテキストエディタでCSVを開いた際に「""」の状態を示します。
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {renderTable()}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box style={{ display: "hidden" }}>
        <CSVLink ref={clink} data={data} headers={MedicalCSVHeader} filename={"medical_template.csv"} />
      </Box>
    </>
  )
}
