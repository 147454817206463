import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Backdrop,
  Box,
  Button, CircularProgress,
  Paper, Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import {
  getDepartmentInformationByCSV,
  IMedicalDepartment,
  MedicalCSVHeader,
  MedicalDepartmentCSVHeader
} from "../../utils/medicalDao"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { useFirestore } from "../../utils/firestore"
import moment from "moment"

export const DepartmentDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<IMedicalDepartment[]>([])
  const [ done, setDone ] = useState<boolean>(false)
  const firestore = useFirestore()
  const [ progress, setProgress ] = useState<boolean>(false);
  const [ progressNum, setProgressNum ] = useState<number>(0);

  const renderTable = () => {
    return MedicalDepartmentCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current && firestore) {

      setProgress(true)
      try {
        const result = await getDepartmentInformationByCSV(firestore,setProgressNum)
        setProgress(false)
        setProgressNum(0)
        setData(result)
        if(!result.length){
          alert("データ0件")
          setDone(true)
        }else{
          setDone(true)
        }
      }catch (e) {
        setProgress(false)
      }
    }
  }, [ clink, firestore ])

  useEffect(() => {
    if (data.length && done) {
      // clink.currentがnullかどうかの確認
      if (clink.current && clink.current.link) {
        clink.current.link.click()
      } else {
        console.error("clink.current is null or clink.current.link is undefined");
        setProgress(false)
      }
    }
    setDone(false)
  }, [ done ])

  return (
    <>
    {progress ?
      <Backdrop open={progress}>
        <CircularProgress variant="determinate" value={progressNum} />
        <Box
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="textPrimary"
          >{`${Math.round(progressNum)}%`}</Typography>
        </Box>
      </Backdrop>
      :
      <>
      <Box style={{ display:"flex", justifyContent: "flex-end" }}>
        <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>医療機関情報ダウンロード</Button>
      </Box>

      <Box className={classes.verticalMargin}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant={"subtitle1"}>
              各列の詳細を確認する
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableBody>
                  {renderTable()}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>

        <Box style={{ display: progress ? "none" : "block" }}>
          <CSVLink ref={clink} data={data} headers={MedicalDepartmentCSVHeader} filename={`medical_department_${moment().format("YYYYMMDD")}.csv`} />
        </Box>
      </>
      }
    </>
  )
}
