import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { useFirestore } from "../../utils/firestore"
import { useFirebaseFunction } from "../../utils/functions"
import { getProductInformationByCSV, ProductCSVHeader, ProductExportCSVHeader } from "../../utils/productDao"

export const ProductTemplateDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<any[]>([])
  const [ done, setDone ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<boolean>(false);
  const [ progressNum, setProgressNum ] = useState<number>(0);
  const firestore = useFirestore()
  const functions = useFirebaseFunction()

  const renderTable = () => {
    return ProductCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current && firestore) {

      setProgress(true)
      try {
        const result = await getProductInformationByCSV(firestore,functions,setProgressNum)
        setProgress(false)
        setProgressNum(0)
        setData(result)
        setDone(true)
        if(!result.length){
          alert("データ0件")
        }
      }catch (e) {
        setProgress(false)
      }

    }
  }, [ clink, firestore ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
      setDone(false)
    }
  }, [ done ])

  return (
    <>
      <Box style={{ display:"flex", justifyContent: "flex-end" }}>
        <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>テンプレートをダウンロード</Button>
      </Box>

      <Box className={classes.verticalMargin}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant={"subtitle1"}>
              各列の詳細を確認する
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <Typography color={"error"} variant={"caption"}>
                        数値は必ず半角でご入力下さい。また、空とはテキストエディタでCSVを開いた際に「""」の状態を示します。
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {renderTable()}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>

      <Box style={{ display: "hidden" }}>
        <CSVLink ref={clink} data={data} headers={ProductExportCSVHeader} filename={"product_template.csv"} />
      </Box>
    </>
  )
}
