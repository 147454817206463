import firebase from "gatsby-plugin-firebase"
import { ICSVHeader } from "./medicalDao"
import moment from "moment"


export interface IExportReview {
  patient_name: string
  medical_name: string
  score: string
  detail: string
  status: string
  created_at?: any
}

export const ReviewExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "利用者名", key: "patient_name", comment: "利用者名" },
  { type: "string", label: "医療機関名", key: "medical_name", comment: "医療機関名" },
  { type: "string", label: "評価", key: "score", comment: "評価" },
  { type: "string", label: "評価コメント", key: "detail", comment: "評価コメント" },
  { type: "string", label: "ステータス", key: "status", comment: "ステータス" },
  { type: "string", label: "作成日", key: "created_at", comment: "作成日" },
]

export const getReviewInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined , functions: firebase.functions.Functions | undefined) => {
  let retVal: IExportReview[] = []
  try {
    if (firestore) {
      const reviews = await firestore.collection("review").get()

      const promiseArray : Promise<any>[] = []
      for (const reviewsKey in reviews.docs) {
        const review_data = reviews.docs[reviewsKey].data()
        promiseArray.push(new Promise<any>(async(resolve) => {
          const reserve = await firestore.collection("reserve").doc(review_data.reserve_id).get()
          const medical = await firestore.collection("medical").doc(review_data.medical_id).get()
          if (reserve && medical){
            const reserve_data = reserve.data()
            const medical_data = medical.data()
            if(reserve_data && medical_data){
              resolve({
                patient_name: reserve_data.patient_name,
                medical_name: medical_data.name,
                score: review_data.score,
                detail: review_data.detail,
                status: review_data.status,
                created_at: review_data.created_at ?　moment(Number(review_data.created_at.seconds.toString() + "000")).format("YYYY/MM/DD") : "",
              })
            }else{
              resolve(undefined)
            }
          }
        }))

      }

      const retVal_temp = await Promise.all(promiseArray)
      retVal = retVal_temp.filter(v => v)

      // reviews.forEach((review) => {
      //   const review_data = review.data()
      //   console.log("review_data.reserve_id",review_data.reserve_id)
      //   const reserve = reserves.find(value => value.id == review_data.reserve_id)
      //   console.log("reserve",reserve.data())
      //   if(reserve.data()){
      //     retVal.push({
      //       patient_name: reserve.data().patient_name,
      //       medical_name: reserve.data().medical_id,
      //       score: review_data.score,
      //       detail: review_data.detail,
      //       status: review_data.status,
      //       created_at: review_data.created_at ?　moment(review_data.created_at).format("YYYYMMDD") : "",
      //     })
      //   }
      // })

    }
  } catch (e) {
    console.log(e)
  }
  console.log("retVal",retVal)
  return retVal
}
