import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Backdrop,
  Box,
  Button, Checkbox, CircularProgress, FormControlLabel, FormGroup,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { useFirestore } from "../../utils/firestore"
import { useFirebaseFunction } from "../../utils/functions"
import {
  CouponReserveExportCSVHeader,
  getCouponReserveInformationByCSV,
  IExportReserve,
} from "../../utils/reserveDao"
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import jaLocale from 'date-fns/locale/ja'
import moment from "moment"

export const CouponReserveDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<IExportReserve[]>([])
  const [ done, setDone ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<boolean>(false);
  const [ progressNum, setProgressNum ] = useState<number>(0);
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date())
  const [dateTo, setDateTo] = useState<Date | null>(new Date())
  const firestore = useFirestore()
  const functions = useFirebaseFunction()

  const renderTable = () => {
    return CouponReserveExportCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const changeDateFromHandler = (newDate: Date | null): void => {
    setDateFrom(newDate)
  }

  const changeDateToHandler = (newDate: Date | null): void => {
    setDateTo(newDate)
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current && firestore) {

      if(moment(dateFrom).isAfter(moment(dateTo))) {
        alert("日付指定が間違っています")
        return
      }

      setProgress(true)
      try {
        const result = await getCouponReserveInformationByCSV(firestore,functions,dateFrom,dateTo,setProgressNum)
        setProgress(false)
        setProgressNum(0)
        setData(result)
        setDone(true)
      }catch (e) {
        setProgress(false)
        setProgressNum(0)
        setData([])
        setDone(true)
      }

    }
  }, [ clink, firestore,dateFrom,dateTo ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
      setDone(false)
    } else {
      setDone(false)
    }
  }, [ done ])

  return (
    <>

      {progress ?
        <Backdrop open={progress}>
          <CircularProgress variant="determinate" value={progressNum} />
          <Box
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="textPrimary"
            >{`${Math.round(progressNum)}%`}</Typography>
          </Box>
        </Backdrop>
        :
        <>
          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>予約情報出力</Button>
          </Box>

          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <Typography variant={"subtitle1"} style={{paddingLeft: 20,paddingRight: 20}}>
              予約日付：
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
              <DatePicker
                value={dateFrom}
                format="yyyy年M月d日"
                onChange={changeDateFromHandler}
              />
              <Typography variant={"subtitle1"} style={{paddingLeft: 20,paddingRight: 20}}>
                ～
              </Typography>
              <DatePicker
                value={dateTo}
                format="yyyy年M月d日"
                onChange={changeDateToHandler}
              />
            </MuiPickersUtilsProvider>
          </Box>

          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <Typography variant={"subtitle1"} style={{paddingLeft: 20,paddingRight: 20}}>
              ※対象は決済済みの予約のみとなります。
            </Typography>
          </Box>

          <Box className={classes.verticalMargin}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={"subtitle1"}>
                  各列の詳細を確認する
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {renderTable()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box style={{ display: "hidden" }}>
            <CSVLink ref={clink} data={data} headers={CouponReserveExportCSVHeader} filename={`reserve_data_${moment(dateFrom).format("YYYYMMDD")}-${moment(dateTo).format("YYYYMMDD")}.csv`} />
          </Box>

        </>
      }
    </>
  )
}
