import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Backdrop,
  Box,
  Button, Checkbox, CircularProgress, FormControlLabel, FormGroup,
  Paper, Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { useFirestore } from "../../utils/firestore"
import { getUserInformationByCSV, IExportUser, IFirestoreUser, UserExportCSVHeader } from "../../utils/userDao"
import { useFirebaseFunction } from "../../utils/functions"
import moment from "moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import jaLocale from "date-fns/locale/ja"

export const UserDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<IExportUser[]>([])
  const [ done, setDone ] = useState<boolean>(false)
  const [ secureInfo, setSecureInfo ] = useState<boolean>(false)
  const [ myPharmacyInfo, setMyPharmacyInfo ] = useState<boolean>(false)
  const [ medicineNoteInfo, setMedicineNoteInfo ] = useState<boolean>(false)
  const [ dateInfo, setDateInfo ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<boolean>(false);
  const [ progressNum, setProgressNum ] = useState<number>(0);
  const [ mailInfo, setMailInfo ] = useState<boolean>(false)
  const [dateFrom, setDateFrom] = useState<Date | null>(new Date())
  const [dateTo, setDateTo] = useState<Date | null>(new Date())
  const firestore = useFirestore()
  const functions = useFirebaseFunction()

  const renderTable = () => {
    return UserExportCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const changeDateFromHandler = (newDate: Date | null): void => {
    setDateFrom(newDate)
  }

  const changeDateToHandler = (newDate: Date | null): void => {
    setDateTo(newDate)
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current && firestore) {

      if(dateInfo){
        if(moment(dateFrom).isAfter(moment(dateTo))) {
          alert("日付指定が間違っています")
          return
        }
      }

      setProgress(true)
      try {
        const result = await getUserInformationByCSV(firestore,functions,secureInfo,medicineNoteInfo,dateInfo,dateFrom,dateTo,setProgressNum,mailInfo,myPharmacyInfo)
        setProgress(false)
        setProgressNum(0)
        setData(result)
        setDone(true)
        if(!result.length){
          alert("データ0件")
        }
      }catch (e) {
        setProgress(false)
      }

    }
  }, [ clink, firestore, secureInfo, myPharmacyInfo, medicineNoteInfo, dateInfo, dateFrom, dateTo, mailInfo ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
    }
    setDone(false)
  }, [ done ])

  const handleChange = (event: any) => {
    setMedicineNoteInfo(event.target.checked)
  };


  const handleSecureChange = (event: any) => {
    setSecureInfo(event.target.checked)
  };

  const handleMyPharmacyChange = (event: any) => {
    setMyPharmacyInfo(event.target.checked)
  };


  const handleMailChange = (event: any) => {
    setMailInfo(event.target.checked)
  };

  const handleDateChange = (event: any) => {
    setDateInfo(event.target.checked)
  };

  return (
    <>

      {progress ?
        <Backdrop open={progress}>
          <CircularProgress variant="determinate" value={progressNum} />
          <Box
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="textPrimary"
            >{`${Math.round(progressNum)}%`}</Typography>
          </Box>
        </Backdrop>
        :
        <>
          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>ユーザー情報ダウンロード</Button>
          </Box>

          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox
                  checked={dateInfo}
                  onChange={handleDateChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              } label="作成日付で絞り込み" />
            </FormGroup>
          </Box>

          {dateInfo ?
          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <Typography variant={"subtitle1"} style={{paddingLeft: 20,paddingRight: 20}}>
              ユーザー作成日付：
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
              <DatePicker
                value={dateFrom}
                format="yyyy年M月d日"
                onChange={changeDateFromHandler}
              />
              <Typography variant={"subtitle1"} style={{paddingLeft: 20,paddingRight: 20}}>
                ～
              </Typography>
              <DatePicker
                value={dateTo}
                format="yyyy年M月d日"
                onChange={changeDateToHandler}
              />
            </MuiPickersUtilsProvider>
          </Box>
          : undefined}
          {/*<Box style={{ display:"flex", justifyContent: "flex-end" }}>*/}
          {/*  <FormGroup>*/}
          {/*    <FormControlLabel control={*/}
          {/*      <Checkbox*/}
          {/*        checked={mailInfo}*/}
          {/*        onChange={handleMailChange}*/}
          {/*        inputProps={{ 'aria-label': 'controlled' }}*/}
          {/*      />*/}
          {/*    } label="メールアドレス情報を含める　※作成日付が長期間の場合、時間がかかります。不要な場合はチェックしないでください。" />*/}
          {/*  </FormGroup>*/}
          {/*</Box>*/}
          {/*<Box style={{ display:"flex", justifyContent: "flex-end" }}>*/}
          {/*  <FormGroup>*/}
          {/*    <FormControlLabel control={*/}
          {/*      <Checkbox*/}
          {/*        checked={medicineNoteInfo}*/}
          {/*        onChange={handleChange}*/}
          {/*        inputProps={{ 'aria-label': 'controlled' }}*/}
          {/*      />*/}
          {/*    } label="お薬手帳情報を含める　※非常に時間がかかります。不要な場合はチェックしないでください。" />*/}
          {/*  </FormGroup>*/}
          {/*</Box>*/}

          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox
                  checked={myPharmacyInfo}
                  onChange={handleMyPharmacyChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              } label="My薬局情報を含める　※不要な場合はチェックしないでください。" />
            </FormGroup>
          </Box>

          <Box style={{ display:"flex", justifyContent: "flex-end" }}>
            <FormGroup>
              <FormControlLabel control={
                <Checkbox
                  checked={secureInfo}
                  onChange={handleSecureChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              } label="セキュア情報(クレジットカード有無、サブスク情報）を含める　※不要な場合はチェックしないでください。" />
            </FormGroup>
          </Box>

          <Box className={classes.verticalMargin}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={"subtitle1"}>
                  各列の詳細を確認する
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {renderTable()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box style={{ display: "hidden" }}>
            <CSVLink ref={clink} data={data} headers={UserExportCSVHeader} filename={dateInfo ? `user_data_${moment(dateFrom).format("YYYYMMDD")}-${moment(dateTo).format("YYYYMMDD")}.csv` : "user_data.csv"} />
          </Box>

        </>
      }
    </>
  )
}
