import React, { FunctionComponent } from "react"
import { OrderDownload } from "./Order/orderDownload"

export const OrderDownloadPage: FunctionComponent = () => {
  return (
    <>
      <OrderDownload />
    </>
  )
}
