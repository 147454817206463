import React, { FunctionComponent } from "react"
import { PudoDownload } from "./Pudo/pudoDownload"

export const PudoB2CloudPage: FunctionComponent = () => {
  return (
    <>
      <PudoDownload />
    </>
  )
}
