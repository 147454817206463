import React, { FunctionComponent } from "react"
import { ReviewDownload } from "./Review/reviewDownload"
import { ProductPage } from "./product"
import { ProductPricePage } from "./product_price"

export const EcProductPricePage: FunctionComponent = () => {
  return (
    <>
      <ProductPricePage />
    </>
  )
}
