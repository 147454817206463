import React, { FunctionComponent } from "react"
import { ReviewDownload } from "./Review/reviewDownload"

export const ReviewPage: FunctionComponent = () => {
  return (
    <>
      <ReviewDownload />
    </>
  )
}
