import React from "react"
import { IRegisterResponse } from "../../utils/medicalDao"
import { Box, Divider, Paper, Typography } from "@material-ui/core"
import { useStyles } from "../../styles/useStyle"

export interface IResultProps {
  result: IRegisterResponse[]
}

export const Result = React.memo((props: IResultProps) => {
  const classes = useStyles()
  const { result } = props
  let retVal = null
  if (result.length) {
    const messages = result.map((value, index) => (
      <Typography variant={"body2"} key={`result_${index}`}>{value.index}行目: {value.result}</Typography>
    ))
    retVal = (
      <Paper className={classes.paperContainer}>
        <Typography component={"h3"} variant={"h5"}>登録結果</Typography>
        <Divider />
        <Box className={classes.wrapMargin}>
          {messages}
        </Box>
      </Paper>
    )

  }

  return retVal
})
