import React, { FunctionComponent } from "react"
import { DoctorDownload } from "./User/doctorDownload"

export const DoctorDownloadPage: FunctionComponent = () => {
  return (
    <>
      <DoctorDownload />
    </>
  )
}
