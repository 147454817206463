import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Backdrop,
  Box,
  Button, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup,
  Table, TableBody, TableCell, TableContainer, TableRow,
  Typography
} from "@material-ui/core"
import { CSVLink } from "react-csv"
import { useStyles } from "../../styles/useStyle"
import { ExpandMore } from "@material-ui/icons"
import { useFirestore } from "../../utils/firestore"
import {
  DoctorExportCSVHeader, getDoctorInformationByCSV, IExportDoctor,
} from "../../utils/userDao"
import { useFirebaseFunction } from "../../utils/functions"

export const DoctorDownload: FunctionComponent = () => {
  const classes = useStyles()
  const clink = useRef<any>(null)
  const [ data, setData ] = useState<IExportDoctor[]>([])
  const [ done, setDone ] = useState<boolean>(false)
  const [ progress, setProgress ] = useState<boolean>(false);
  const [ progressNum, setProgressNum ] = useState<number>(0);
  const firestore = useFirestore()
  const functions = useFirebaseFunction()

  const renderTable = () => {
    return DoctorExportCSVHeader.map((row, index) => (
      <TableRow key={`table_row_${index}`}>
        <TableCell>{row.label}</TableCell>
        <TableCell>{row.comment}</TableCell>
      </TableRow>
    ))
  }

  const downloadTemplate = useCallback(async () => {
    if (clink.current && firestore) {

      setProgress(true)
      try {
        const result = await getDoctorInformationByCSV(firestore,functions,setProgressNum)
        console.log("result.length",result.length)
        setProgress(false)
        setProgressNum(0)
        setData(result)
        setDone(true)
        if(!result.length){
          alert("データ0件")
        }
      }catch (e) {
        setProgress(false)
      }

    }
  }, [ clink, firestore ])

  useEffect(() => {
    if (data.length && done) {
      clink.current.link.click()
    }
    setDone(false)
  }, [ done ])

  return (
    <>
      {progress ?
        <Backdrop open={progress}>
          <CircularProgress variant="determinate" value={progressNum} />
          <Box
            style={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="textPrimary"
            >{`${Math.round(progressNum)}%`}</Typography>
          </Box>
        </Backdrop>
        :
        <>
          <Box style={{ display:"flex", justifyContent: "flex-end", marginBottom: 16 }}>
            <Button variant={"outlined"} onClick={downloadTemplate} color={"primary"}>ユーザー情報ダウンロード</Button>
          </Box>

          <Box className={classes.verticalMargin}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography variant={"subtitle1"}>
                  各列の詳細を確認する
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer>
                  <Table>
                    <TableBody>
                      {renderTable()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box style={{ display: "hidden" }}>
            <CSVLink ref={clink} data={data} headers={DoctorExportCSVHeader} filename={"doctor_list.csv"} />
          </Box>

        </>
      }
    </>
  )
}
