import React, { FunctionComponent } from "react"
import { PointAwardDownload } from "./User/pointAwardDownload"

export const PointAwardDownloadPage: FunctionComponent = () => {
  return (
    <>
      <PointAwardDownload />
    </>
  )
}
