import * as holiday_jp from "@holiday-jp/holiday_jp"
import moment from "moment-timezone"
moment.tz.setDefault("Asia/Tokyo")

export const getJpHolidaysObject = () => {
  const jpHolidayArray = holiday_jp.between(moment().startOf("year").toDate(), moment().startOf("year").add(50,"year").toDate())

  let tmpJpArray : any = {}
  jpHolidayArray.forEach(data => {
    let dateKey = moment(data.date).format('x')
    tmpJpArray[dateKey] = {
      is_clinic: false,
      title: data.name
    }
  })
  return tmpJpArray
}
