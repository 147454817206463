import firebase from "gatsby-plugin-firebase"
import { ICSVHeader, IRegisterResponse } from "./medicalDao"
import moment from "moment"
import {
  DisplayTimeType, INSTITUTION, MEDICINE_RECEIVE_TYPE,
  MedicineReceiveType, PAYMENT_METHOD,
  ReserveStatusType,
  ReserveTimeType,
  ReserveType
} from "../../../functions/src/types"
import { DisplayTimeKey } from "./firestore"
import { forEach } from "lodash"

const col : any = {
  A : "お客様管理番号\n半角英数字50文字",
  B : "送り状種類\n半角数字1文字\n 0 : 発払い\n 2 : コレクト\n 3 : クロネコゆうメール\n 4 : タイム\n 5 : 着払い\n 6 : 発払い（複数口）\n 7 : ネコポス・クロネコゆうパケット\n 8 : 宅急便コンパクト\n 9 : 宅急便コンパクトコレクト\n\n(※宅急便_必須項目)\n(※クロネコゆうメール_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)\n",
  C : "クール区分\n半角数字1文字\n0または空白 : 通常\n 1 : クール冷凍\n 2 : クール冷蔵\n\n※「0:発払い」、「2:コレクト」、「5:着払い」のみ、ご利用頂けます。上記以外の送り状の場合、空白扱いで取り込みます。",
  D : "伝票番号\n半角数字12文字\n\n※B2クラウドにて付与",
  E : "出荷予定日\n半角10文字\n｢YYYY/MM/DD｣で入力してください。\n\n(※宅急便_必須項目)\n(※クロネコゆうメール_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  F : "お届け予定日\n半角10文字\n｢YYYY/MM/DD｣で入力してください。\n\n※入力なしの場合、印字されません。\n※「最短日」と入力可",
  G : "配達時間帯\n半角4文字\n発払・コレクト・着払・宅急便コンパクト・宅急便コンパクトコレクト・発払（複数口）　の場合\n 空白 : 指定なし\n 0812 : 午前中\n 1416 : 14～16時\n 1618 : 16～18時\n 1820 : 18～20時\n 1921 : 19～21時\n\nタイム\n 0010 : 午前10時まで\n 0017 : 午後5時まで",
  H : "お届け先コード\n半角英数字20文字",
  I : "お届け先電話番号\n半角数字15文字ハイフン含む\n\n(※宅急便_必須項目)\n(※クロネコゆうメール_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  J : "お届け先電話番号枝番\n半角数字2文字",
  K : "お届け先郵便番号\n半角数字8文字\nハイフンなし7文字も可\n\n(※宅急便_必須項目)\n(※クロネコゆうメール_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  L : "お届け先住所\n全角/半角\n都道府県（４文字）\n市区郡町村（１２文字）\n町・番地（１６文字）\n\n(※宅急便_必須項目)\n(※クロネコゆうメール_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  M : "お届け先アパートマンション名\n全角/半角 \n16文字/32文字 ",
  N : "お届け先会社・部門１\n全角/半角\n25文字/50文字 ",
  O : "お届け先会社・部門２\n全角/半角 \n25文字/50文字 ",
  P : "お届け先名\n全角/半角\n16文字/32文字 \n\n(※宅急便_必須項目)\n(※クロネコゆうメール_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  Q : "お届け先名(ｶﾅ)\n半角カタカナ 50文字 ",
  R : "敬称\n全角/半角 2文字/4文字\nクロネコゆうメールの場合に指定可能\n【入力例】\n様・御中・殿・行・係・宛・先生・なし",
  S : "ご依頼主コード\n半角英数字 20文字 ",
  T : "ご依頼主電話番号\n半角数字15文字ハイフン含む\n\n(※宅急便_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  U : "ご依頼主電話番号枝番\n半角数字 2文字 ",
  V : "ご依頼主郵便番号\n半角数字8文字\nハイフンなし半角7文字も可 \n\n(※宅急便_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  W : "ご依頼主住所\n全角/半角32文字/64文字\n都道府県（４文字）\n市区郡町村（１２文字）\n町・番地（１６文字）\n\n(※宅急便_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  X : "ご依頼主アパートマンション\n全角/半角 16文字/32文字 ",
  Y : "ご依頼主名\n全角/半角 16文字/32文字 \n\n(※宅急便_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  Z : "ご依頼主名(ｶﾅ)\n半角カタカナ 50文字",
  AA : "品名コード１\n半角英数字 30文字 ",
  AB : "品名１\n全角/半角 25文字/50文字 \n\n(※宅急便_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  AC : "品名コード２\n半角英数字 30文字",
  AD : "品名２\n全角/半角 25文字/50文字 ",
  AE : "荷扱い１\n全角/半角 10文字/20文字 ",
  AF : "荷扱い２\n全角/半角 10文字/20文字 ",
  AG : "記事\n全角/半角 22文字/44文字 ",
  AH : "ｺﾚｸﾄ代金引換額（税込)\n半角数字 7文字\n\n※コレクトの場合は必須\n300,000円以下　1円以上\n※但し、宅急便コンパクトコレクトの場合は\n30,000円以下　　1円以上",
  AI : "内消費税額等\n半角数字 7文字\n\n※コレクトの場合は必須 \n※コレクト代金引換額（税込)以下",
  AJ : "止置き\n半角数字 1文字\n0 : 利用しない\n1 : 利用する ",
  AK : "営業所コード\n半角数字 6文字\n\n※止置きを利用する場合は必須 ",
  AL : "発行枚数\n半角数字 2文字\n\n※発払い・タイム・着払い、ネコポス・クロネコゆうパケット・発払い（複数口）のみ指定可能",
  AM : "個数口表示フラグ\n半角数字 1文字\n1 : 印字する\n2 : 印字しない \n3 : 枠と口数を印字する\n\n※宅急便コンパクト、宅急便コンパクトコレクトは対象外\n※複数口の場合、本項目の指定に関係なく、3 : 枠と口数を印字する扱いとする",
  AN : "請求先顧客コード\n半角数字10～12文字\n\n(※宅急便_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  AO : "請求先分類コード\n空白または半角数字3文字\n",
  AP : "運賃管理番号\n半角数字2文字\n\n(※宅急便_必須項目)\n(※ネコポス・クロネコゆうパケット_必須項目)",
  AQ : "クロネコwebコレクトデータ登録\n半角数字 1文字\n0 : 無し\n1 : 有り ",
  AR : "クロネコwebコレクト加盟店番号\n半角英数字 9文字 \n\n※クロネコwebコレクトデータ有りの場合は必須 ",
  AS : "クロネコwebコレクト申込受付番号１\n半角英数字 23文字\n\n※クロネコwebコレクトデータ有りの場合は必須 ",
  AT : "クロネコwebコレクト申込受付番号２\n半角英数字 23文字\n\n※発払い（複数口）の場合は、設定不可",
  AU : "クロネコwebコレクト申込受付番号３\n半角英数字 23文字\n\n※発払い（複数口）の場合は、設定不可",
  AV : "お届け予定ｅメール利用区分\n半角数字 1文字\n0 : 利用しない\n1 : 利用する ",
  AW : "お届け予定ｅメールe-mailアドレス\n半角英数字＆記号 60文字\n\n※お届け予定eメールを利用する場合は必須 ",
  AX : "入力機種\n半角数字 1文字\n1 : ＰＣ\n2 : 携帯電話\n\n※お届け予定eメールを利用する場合は必須",
  AY : "お届け予定ｅメールメッセージ\n全角 74文字\n\n\n※お届け予定eメールを利用する場合は必須",
  AZ : "お届け完了ｅメール利用区分\n半角数字 1文字\n0 : 利用しない\n1 : 利用する ",
  BA : "お届け完了ｅメールe-mailアドレス\n半角英数字 60文字\n\n※お届け完了eメールを利用する場合は必須 ",
  BB : "お届け完了ｅメールメッセージ\n全角 159文字 \n\n※お届け完了eメールを利用する場合は必須 ",
  BC : "クロネコ収納代行利用区分\n半角数字１文字\n0 : 利用しない\n1 : 利用する ",
  BD : "予備\n半角数字１文字",
  BE : "収納代行請求金額(税込)\n半角数字７文字",
  BF : "収納代行内消費税額等\n半角数字７文字",
  BG : "収納代行請求先郵便番号\n半角数字＆ハイフン8文字\nハイフンなし半角7文字も可 ",
  BH : "収納代行請求先住所\n全角/半角　32文字/64文字\n都道府県（４文字）\n市区郡町村（１２文字）\n町・番地（１６文字）",
  BI : "収納代行請求先住所（アパートマンション名）\n全角/半角　16文字/32文字",
  BJ : "収納代行請求先会社・部門名１\n全角/半角　25文字/50文字",
  BK : "収納代行請求先会社・部門名２\n全角/半角　25文字/50文字",
  BL : "収納代行請求先名(漢字)\n全角/半角　16文字/32文字",
  BM : "収納代行請求先名(カナ)\n半角カタカナ50文字",
  BN : "収納代行問合せ先名(漢字)\n全角/半角　16文字/32文字",
  BO : "収納代行問合せ先郵便番号\n半角数字＆ハイフン8文字\nハイフンなし半角7文字も可 ",
  BP : "収納代行問合せ先住所\n全角/半角　32文字/64文字\n都道府県（４文字）\n市区郡町村（１２文字）\n町・番地（１６文字）",
  BQ : "収納代行問合せ先住所（アパートマンション名）\n全角/半角　16文字/32文字",
  BR : "収納代行問合せ先電話番号\n半角数字＆ハイフン15文字",
  BS : "収納代行管理番号\n半角英数字20文字",
  BT : "収納代行品名\n全角/半角　25文字/50文字",
  BU : "収納代行備考\n全角/半角　14文字/28文字",
  BV : "複数口くくりキー\n半角英数字20文字\n\n※「出荷予定個数」が2以上で「個数口枠の印字」で 「3 : 枠と口数を印字する」を選択し、且つ「複数口くくりキー」が空白の場合は、送り状発行時に「B2」という文言を自動補完する。\n※送り状種類6：発払い（複数口）を選択時、お届け日時とお届け先が同じ荷物に対し、同一の複数口くくりキーを設定してください。1度のデータ取込で、異なるお届け日時・お届け先に対して同一の複数口くくりキーは使用できません。なお、発払い（複数口）に限り、データ取込後に複数口くくりキーは親伝票番号（数字12桁）に置き換わります。",
  BW : "検索キータイトル1\n全角/半角 \n10文字/20文字 ",
  BX : "検索キー1\n半角英数字\n20文字",
  BY : "検索キータイトル2\n全角/半角 \n10文字/20文字 ",
  BZ : "検索キー2\n半角英数字\n20文字",
  CA : "検索キータイトル3\n全角/半角 \n10文字/20文字 ",
  CB : "検索キー3\n半角英数字\n20文字",
  CC : "検索キータイトル4\n全角/半角 \n10文字/20文字 ",
  CD : "検索キー4\n半角英数字\n20文字",
  CE : "検索キータイトル5\n\n※入力時は不要。出力時に自動反映。\n※「ユーザーID」という文言を送り状発行時に固定で自動補完する。",
  CF : "検索キー5\n\n※入力時は不要。出力時に自動反映。\n※送り状発行時のユーザーIDを固定で自動補完する。",
  CG : "予備",
  CH : "予備",
  CI : "投函予定メール利用区分\n半角数字\n1文字\n0 : 利用しない\n1 : 利用する PC宛て\n2 : 利用する モバイル宛て",
  CJ : "投函予定メールe-mailアドレス\n半角英数字＆記号\n60文字",
  CK : "投函予定メールメッセージ\n全角/半角\n74文字/148文字\n\n※半角カタカナ及び半角スペースは使えません。",
  CL : "投函完了メール（お届け先宛）利用区分\n半角数字\n1文字\n0 : 利用しない\n1 : 利用する PC宛て\n2 : 利用する モバイル宛て",
  CM : "投函完了メール（お届け先宛）e-mailアドレス\n半角英数字＆記号\n60文字",
  CN : "投函完了メール（お届け先宛）メールメッセージ\n全角/半角\n159文字/318文字\n\n※半角カタカナ及び半角スペースは使えません。",
  CO : "投函完了メール（ご依頼主宛）利用区分\n半角数字\n1文字\n0 : 利用しない\n1 : 利用する PC宛て\n2 : 利用する モバイル宛て",
  CP : "投函完了メール（ご依頼主宛）e-mailアドレス\n半角英数字＆記号\n60文字",
  CQ : "投函完了メール（ご依頼主宛）メールメッセージ\n全角/半角\n159文字/318文字\n\n※半角カタカナ及び半角スペースは使えません。",
  CR : "連携管理番号\n半角英数字＆記号\n70文字\n※直送りを利用する場合は必須",
  CS : "通知メールアドレス\n半角英数字＆記号\n60文字\n※直送りを利用する場合で、ヤマト運輸からメールで通知する場合は必須"
}


export interface IExportPudo {
  A : string,
  B : string,
  C : string,
  D : string,
  E : string,
  F : string,
  G : string,
  H : string,
  I : string,
  J : string,
  K : string,
  L : string,
  M : string,
  N : string,
  O : string,
  P : string,
  Q : string,
  R : string,
  S : string,
  T : string,
  U : string,
  V : string,
  W : string,
  X : string,
  Y : string,
  Z : string,
  AA : string,
  AB : string,
  AC : string,
  AD : string,
  AE : string,
  AF : string,
  AG : string,
  AH : string,
  AI : string,
  AJ : string,
  AK : string,
  AL : string,
  AM : string,
  AN : string,
  AO : string,
  AP : string,
  AQ : string,
  AR : string,
  AS : string,
  AT : string,
  AU : string,
  AV : string,
  AW : string,
  AX : string,
  AY : string,
  AZ : string,
  BA : string,
  BB : string,
  BC : string,
  BD : string,
  BE : string,
  BF : string,
  BG : string,
  BH : string,
  BI : string,
  BJ : string,
  BK : string,
  BL : string,
  BM : string,
  BN : string,
  BO : string,
  BP : string,
  BQ : string,
  BR : string,
  BS : string,
  BT : string,
  BU : string,
  BV : string,
  BW : string,
  BX : string,
  BY : string,
  BZ : string,
  CA : string,
  CB : string,
  CC : string,
  CD : string,
  CE : string,
  CF : string,
  CG : string,
  CH : string,
  CI : string,
  CJ : string,
  CK : string,
  CL : string,
  CM : string,
  CN : string,
  CO : string,
  CP : string,
  CQ : string,
  CR : string,
  CS : string,
}

export const PudoExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: col.A, key: "A", comment: "A" },
  { type: "string", label: col.B, key: "B", comment: "B" },
  { type: "string", label: col.C, key: "C", comment: "C" },
  { type: "string", label: col.D, key: "D", comment: "D" },
  { type: "string", label: col.E, key: "E", comment: "E" },
  { type: "string", label: col.F, key: "F", comment: "F" },
  { type: "string", label: col.G, key: "G", comment: "G" },
  { type: "string", label: col.H, key: "H", comment: "H" },
  { type: "string", label: col.I, key: "I", comment: "I" },
  { type: "string", label: col.J, key: "J", comment: "J" },
  { type: "string", label: col.K, key: "K", comment: "K" },
  { type: "string", label: col.L, key: "L", comment: "L" },
  { type: "string", label: col.M, key: "M", comment: "M" },
  { type: "string", label: col.N, key: "N", comment: "N" },
  { type: "string", label: col.O, key: "O", comment: "O" },
  { type: "string", label: col.P, key: "P", comment: "P" },
  { type: "string", label: col.Q, key: "Q", comment: "Q" },
  { type: "string", label: col.R, key: "R", comment: "R" },
  { type: "string", label: col.S, key: "S", comment: "S" },
  { type: "string", label: col.T, key: "T", comment: "T" },
  { type: "string", label: col.U, key: "U", comment: "U" },
  { type: "string", label: col.V, key: "V", comment: "V" },
  { type: "string", label: col.W, key: "W", comment: "W" },
  { type: "string", label: col.X, key: "X", comment: "X" },
  { type: "string", label: col.Y, key: "Y", comment: "Y" },
  { type: "string", label: col.Z, key: "Z", comment: "Z" },
  { type: "string", label: col.AA, key: "AA", comment: "AA" },
  { type: "string", label: col.AB, key: "AB", comment: "AB" },
  { type: "string", label: col.AC, key: "AC", comment: "AC" },
  { type: "string", label: col.AD, key: "AD", comment: "AD" },
  { type: "string", label: col.AE, key: "AE", comment: "AE" },
  { type: "string", label: col.AF, key: "AF", comment: "AF" },
  { type: "string", label: col.AG, key: "AG", comment: "AG" },
  { type: "string", label: col.AH, key: "AH", comment: "AH" },
  { type: "string", label: col.AI, key: "AI", comment: "AI" },
  { type: "string", label: col.AJ, key: "AJ", comment: "AJ" },
  { type: "string", label: col.AK, key: "AK", comment: "AK" },
  { type: "string", label: col.AL, key: "AL", comment: "AL" },
  { type: "string", label: col.AM, key: "AM", comment: "AM" },
  { type: "string", label: col.AN, key: "AN", comment: "AN" },
  { type: "string", label: col.AO, key: "AO", comment: "AO" },
  { type: "string", label: col.AP, key: "AP", comment: "AP" },
  { type: "string", label: col.AQ, key: "AQ", comment: "AQ" },
  { type: "string", label: col.AR, key: "AR", comment: "AR" },
  { type: "string", label: col.AS, key: "AS", comment: "AS" },
  { type: "string", label: col.AT, key: "AT", comment: "AT" },
  { type: "string", label: col.AU, key: "AU", comment: "AU" },
  { type: "string", label: col.AV, key: "AV", comment: "AV" },
  { type: "string", label: col.AW, key: "AW", comment: "AW" },
  { type: "string", label: col.AX, key: "AX", comment: "AX" },
  { type: "string", label: col.AY, key: "AY", comment: "AY" },
  { type: "string", label: col.AZ, key: "AZ", comment: "AZ" },
  { type: "string", label: col.BA, key: "BA", comment: "BA" },
  { type: "string", label: col.BB, key: "BB", comment: "BB" },
  { type: "string", label: col.BC, key: "BC", comment: "BC" },
  { type: "string", label: col.BD, key: "BD", comment: "BD" },
  { type: "string", label: col.BE, key: "BE", comment: "BE" },
  { type: "string", label: col.BF, key: "BF", comment: "BF" },
  { type: "string", label: col.BG, key: "BG", comment: "BG" },
  { type: "string", label: col.BH, key: "BH", comment: "BH" },
  { type: "string", label: col.BI, key: "BI", comment: "BI" },
  { type: "string", label: col.BJ, key: "BJ", comment: "BJ" },
  { type: "string", label: col.BK, key: "BK", comment: "BK" },
  { type: "string", label: col.BL, key: "BL", comment: "BL" },
  { type: "string", label: col.BM, key: "BM", comment: "BM" },
  { type: "string", label: col.BN, key: "BN", comment: "BN" },
  { type: "string", label: col.BO, key: "BO", comment: "BO" },
  { type: "string", label: col.BP, key: "BP", comment: "BP" },
  { type: "string", label: col.BQ, key: "BQ", comment: "BQ" },
  { type: "string", label: col.BR, key: "BR", comment: "BR" },
  { type: "string", label: col.BS, key: "BS", comment: "BS" },
  { type: "string", label: col.BT, key: "BT", comment: "BT" },
  { type: "string", label: col.BU, key: "BU", comment: "BU" },
  { type: "string", label: col.BV, key: "BV", comment: "BV" },
  { type: "string", label: col.BW, key: "BW", comment: "BW" },
  { type: "string", label: col.BX, key: "BX", comment: "BX" },
  { type: "string", label: col.BY, key: "BY", comment: "BY" },
  { type: "string", label: col.BZ, key: "BZ", comment: "BZ" },
  { type: "string", label: col.CA, key: "CA", comment: "CA" },
  { type: "string", label: col.CB, key: "CB", comment: "CB" },
  { type: "string", label: col.CC, key: "CC", comment: "CC" },
  { type: "string", label: col.CD, key: "CD", comment: "CD" },
  { type: "string", label: col.CE, key: "CE", comment: "CE" },
  { type: "string", label: col.CF, key: "CF", comment: "CF" },
  { type: "string", label: col.CG, key: "CG", comment: "CG" },
  { type: "string", label: col.CH, key: "CH", comment: "CH" },
  { type: "string", label: col.CI, key: "CI", comment: "CI" },
  { type: "string", label: col.CJ, key: "CJ", comment: "CJ" },
  { type: "string", label: col.CK, key: "CK", comment: "CK" },
  { type: "string", label: col.CL, key: "CL", comment: "CL" },
  { type: "string", label: col.CM, key: "CM", comment: "CM" },
  { type: "string", label: col.CN, key: "CN", comment: "CN" },
  { type: "string", label: col.CO, key: "CO", comment: "CO" },
  { type: "string", label: col.CP, key: "CP", comment: "CP" },
  { type: "string", label: col.CQ, key: "CQ", comment: "CQ" },
  { type: "string", label: col.CR, key: "CR", comment: "CR" },
  { type: "string", label: col.CS, key: "CS", comment: "CS" },
]

interface IUserData {
  id: string
  data: firebase.firestore.DocumentData
}

//予約タイプを取得
export const getReserveType = (type: ReserveType) => {
  switch (type) {
    case 1:
      return "クリニック予約"
    case 2:
      return "薬局予約"
    case 3:
      return "来店予約"
    default:
      return ""
  }
}

//お薬配送方法を取得
export const getMedicineReceiveType = (type: MedicineReceiveType) => {
  switch (type) {
    case 0:
      return "通常配送"
    case 1:
      return "処方箋のみ"
    case 2:
      return "お急ぎ便"
    case 10:
      return "対面受取"
    default:
      return ""
  }
}

//お支払方法を取得
export const getPaymentMethod = (type: number) => {
  switch (type) {
    case 0:
      return "クレジットカード決済"
    case 1:
      return "コンビニ後払い決済"
    default:
      return ""
  }
}

//予約ステータスを取得
export const getReserveStatus = (type: ReserveStatusType) => {
  switch (type) {
    case 0:
      return "空"
    case 1:
      return "予約済み"
    case 2:
      return "待合室待機中"
    case 3 :
      return "医師待機中"
    case 4 :
      return "開始"
    case 5 :
      return "診察終了"
    case 70 :
      return "待合室から退室（今すぐ診療のみ使用）"
    case 80 :
      return "利用者によってキャンセル"
    case 81 :
      return "医療機関によってキャンセル"
    case 82 :
      return "システムによってキャンセル"
    case 90 :
      return "支払い済み"
    case 91 :
      return "支払い失敗"
    default:
      return ""
  }
}

const CreateConnectNo = (reserve_id: string, reserveData: any) => {
  try {
    return (reserveData.select_pudo.LOGO_KB ?? "***")  +
      "+" +
      reserveData.select_pudo.F +
      "+" +
      "10110" +
      "+" +
      reserveData.select_pudo.DCD +
      "+" +
      reserve_id +
      "+" +
      "1"
  }catch (e) {
    return ""
  }
}

interface IGetUsersEmails {
  id: string
  email: string
}

export const getPudoInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined, functions: firebase.functions.Functions | undefined, dateFrom: Date | null, dateTo: Date | null, setProgressNum: any, fromFrom: number, fromTo: number) => {
  let retVal: IExportPudo[] = []

  try {
    console.log(dateFrom)
    console.log(moment(dateFrom).valueOf())
    console.log(dateTo)
    console.log(moment(dateTo).valueOf())
    if (firestore) {
      setProgressNum(0)
      const reservesQuery = await firestore.collection("reserve")
        .where("date", '>=', moment(dateFrom).startOf('day').valueOf())
        .where("date", '<=', moment(dateTo).startOf('day').valueOf())
        .where("medicine_receive_type", '==', MEDICINE_RECEIVE_TYPE.PUDO)
      let reserveLastDoc = null;
      let hasNextPage = true;
      let mailList : IGetUsersEmails[] = []
      setProgressNum(5)

      while (hasNextPage) {
        // @ts-ignore
        const { docs, nextPageExists } = await getDocByLimit(reservesQuery, reserveLastDoc, 1000);
        console.log("docs.length",docs.length)
        if (docs.length > 0) {
          const patientIds : string[] = docs.map(doc => doc.data().patient_id);
          if(functions && patientIds.length){
            // 患者IDを集める
            console.log("mail start")
            const func = functions.httpsCallable("getUsersEmails",{timeout : 550 * 1000})
            await func({patient_ids: patientIds}).then((data)=> {
              mailList = data.data.userInfos
              console.log("mailList",mailList)
            }).catch(e => {
              console.log(e)
            })
            console.log("mail end")
          }

          for (const doc of docs) {
            const reserve_data = doc.data()
            //日時の判定
            if(
              !(moment(reserve_data.date).isSame(moment(dateFrom), 'day') && reserve_data.from <= fromFrom) ||
              !(moment(reserve_data.date).isSame(moment(dateTo), 'day') && reserve_data.from > fromTo)
            ) {
              const medical : any = await firestore.collection("medical").doc(reserve_data.medical_id).get();
              const medicalData = medical.data()
              const patient : any = await firestore.collection("private").doc(reserve_data.patient_id).get();
              const patientData = patient.data()

              if (reserve_data && reserve_data.select_pudo) {
                retVal.push({
                  A: "",
                  B: "0",
                  C: "",
                  D: "",
                  E: moment().format("YYYY/MM/DD"),
                  F: "最短日",
                  G: "",
                  H: "",
                  I: patientData.tel,
                  J: "",
                  K: reserve_data.select_pudo.ZIP,
                  L: reserve_data.select_pudo.ADR,
                  M: "",
                  N: reserve_data.select_pudo.NM,
                  O: "",
                  P: patientData.name,
                  Q: "",
                  R: "",
                  S: "",
                  T: medicalData.tel,
                  U: "",
                  V: medicalData.post_code,
                  W: medicalData.prefecture + medicalData.address1 + medicalData.address2,
                  X: "",
                  Y: medicalData.name,
                  Z: "",
                  AA: "",
                  AB: "医薬品",
                  AC: "",
                  AD: "",
                  AE: "",
                  AF: "",
                  AG: "",
                  AH: "",
                  AI: "",
                  AJ: "",
                  AK: "",
                  AL: "",
                  AM: "",
                  AN: medicalData.tel,
                  AO: "",
                  AP: "01",
                  AQ: "",
                  AR: "",
                  AS: "",
                  AT: "",
                  AU: "",
                  AV: "",
                  AW: "",
                  AX: "",
                  AY: "",
                  AZ: "",
                  BA: "",
                  BB: "",
                  BC: "",
                  BD: "",
                  BE: "",
                  BF: "",
                  BG: "",
                  BH: "",
                  BI: "",
                  BJ: "",
                  BK: "",
                  BL: "",
                  BM: "",
                  BN: "",
                  BO: "",
                  BP: "",
                  BQ: "",
                  BR: "",
                  BS: "",
                  BT: "",
                  BU: "",
                  BV: "",
                  BW: "",
                  BX: "",
                  BY: "",
                  BZ: "",
                  CA: "",
                  CB: "",
                  CC: "",
                  CD: "",
                  CE: "",
                  CF: "",
                  CG: "",
                  CH: "",
                  CI: "",
                  CJ: "",
                  CK: "",
                  CL: "",
                  CM: "",
                  CN: "",
                  CO: "",
                  CP: "",
                  CQ: "",
                  CR: CreateConnectNo(doc.id,reserve_data),
                  CS: mailList.find((mail : IGetUsersEmails) => mail.id === reserve_data.patient_id)?.email ?? "",
                })
              } else {
                console.log("No select_pudo")
              }
            }
          }
          // 最後のドキュメントを保持
          reserveLastDoc = docs[docs.length - 1];
        }
        hasNextPage = nextPageExists;
      }
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}

async function getDocByLimit(query: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>, startAfterDoc: any, batchSize: number) {
  // startAfterDocを使用して次のページを取得
  let batchQuery = query.limit(batchSize);
  if (startAfterDoc) {
    batchQuery = batchQuery.startAfter(startAfterDoc);
  }

  const batchSnapshot = await batchQuery.get();
  const docs = batchSnapshot.docs;
  const nextPageExists = docs.length === batchSize;

  return { docs, nextPageExists };
}

