import React from "react"
import { TableCell, TableHead, TableRow, Typography } from "@material-ui/core"
import { ICSVHeader } from "../../utils/medicalDao"
import { useStyles } from "../../styles/useStyle"

export interface ICSVHeaderProps {
  header: ICSVHeader[]
}

export const CsvHeader = React.memo((props: ICSVHeaderProps) => {
  const classes = useStyles()
  let retVal = []
  retVal.push(<TableCell key={`csv_header_no`}><Typography variant={"body2"}>No</Typography></TableCell>)
  retVal = retVal.concat(props.header.map((column, index) => <TableCell
    className={classes.tableCellStyle} key={`csv_header_${index}`}><Typography
    variant={"body2"}>{column.label}</Typography></TableCell>))

  return (
    <TableHead>
      <TableRow>
        {retVal}
      </TableRow>
    </TableHead>
  )
})
