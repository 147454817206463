import firebase from "gatsby-plugin-firebase"
import { ICSVHeader, IRegisterResponse } from "./medicalDao"
import moment from "moment"
import {
  MedicineReceiveType,
} from "../../../functions/src/types"
import { DisplayTimeKey } from "./firestore"


export interface IExportOrder {
  id: string,
  customer_id: string,
  corporation_id: string,
  customer_name: string,
  medical_id: string,
  medical_name: string,
  order_date: string,
  payment_method: string,
  products: string,
  shipping_type: string,
  shopping_status: string,
  delivery_address: string,
  receive_type: string,
  alternative: string,
  delivery_status: string,
  result: string,
  order_no: string,
}

export const OrderExportCSVHeader: ICSVHeader[] = [
  { type: "string", label: "id", key: "id", comment: "id" },
  // { type: "string", label: "customer_id", key: "customer_id", comment: "customer_id" },
  { type: "string", label: "corporation_id", key: "corporation_id", comment: "corporation_id" },
  { type: "string", label: "customer_name", key: "customer_name", comment: "customer_name" },
  { type: "string", label: "medical_id", key: "medical_id", comment: "medical_id" },
  { type: "string", label: "medical_name", key: "medical_name", comment: "medical_name" },
  { type: "string", label: "order_date", key: "order_date", comment: "order_date" },
  { type: "string", label: "payment_method", key: "payment_method", comment: "payment_method" },
  { type: "string", label: "products", key: "products", comment: "products" },
  { type: "string", label: "shipping_type", key: "shipping_type", comment: "shipping_type" },
  { type: "string", label: "shopping_status", key: "shopping_status", comment: "shopping_status" },
  { type: "string", label: "delivery_address", key: "delivery_address", comment: "delivery_address" },
  { type: "string", label: "receive_type", key: "receive_type", comment: "receive_type" },
  { type: "string", label: "alternative", key: "alternative", comment: "alternative" },
  { type: "string", label: "delivery_status", key: "delivery_status", comment: "delivery_status" },
  { type: "string", label: "result", key: "result", comment: "result" },
  { type: "string", label: "order_no", key: "order_no", comment: "order_no" },
]

interface IUserData {
  id: string
  data: firebase.firestore.DocumentData
}

//お薬配送方法を取得
export const getMedicineReceiveType = (type: MedicineReceiveType) => {
  switch (type) {
    case 0:
      return "通常配送"
    case 1:
      return "処方箋のみ"
    case 2:
      return "お急ぎ便"
    case 10:
      return "来店受け取り"
    default:
      return ""
  }
}

//お支払方法を取得
export const getPaymentMethod = (type: number) => {
  switch (type) {
    case 0:
      return "クレジットカード決済"
    case 1:
      return "コンビニ後払い決済"
    default:
      return ""
  }
}

export const getOrderInformationByCSV = async (firestore: firebase.firestore.Firestore | undefined, functions: firebase.functions.Functions | undefined, ec_corporate_id : String | null ,dateFrom: Date | null, dateTo: Date | null, setProgressNum: any) => {
  let retVal: IExportOrder[] = []
  try {
    if (firestore) {
      setProgressNum(0)
      const orders = await firestore.collection("order")
        .where("order_date", '>=', moment(dateFrom).valueOf())
        .where("order_date", '<=', moment(dateTo).valueOf())
        .get()
      const medicals = await firestore.collection("medical")
        .where("ec_corp_id", '==', ec_corporate_id)
        .get()
      setProgressNum(5)

      let i = 0
      const orders_count = orders.size
      orders.forEach(order => {
        i++
        const order_data = order.data()
        if (order_data) {
          retVal.push({
            id: order.id,
            customer_id: order_data.customer_id,
            corporation_id: order_data.corporation_id,
            customer_name: order_data.customer_name,
            medical_id: order_data.medical_id,
            medical_name: medicals.docs.find(medical => medical.id == order_data.medical_id)?.data().name,
            order_date: moment(order_data.order_date).format("YYYY/MM/DD"),
            payment_method: order_data.payment_method,
            products: order_data.products,
            shipping_type: order_data.shipping_type,
            shopping_status: order_data.shopping_status,
            delivery_address: order_data.delivery_address,
            receive_type: order_data.receive_type,
            alternative: order_data.alternative,
            delivery_status: order_data.delivery_status,
            result: order_data.result,
            order_no: order_data.order_no,
          })
        }
        setProgressNum(1 + Math.round((i / orders_count) * 94))
      })
      setProgressNum(100)
    }
  } catch (e) {
    console.log(e)
  }

  return retVal
}
