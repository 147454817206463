import React, { Component, FunctionComponent } from "react"
import { useFirebaseAuth } from "../../utils/auth"
import { RouterProps } from "@reach/router"
import { PageDrawer } from "./drawer"
import { Login } from "../login"

export const PrivateRoute: FunctionComponent<RouterProps> = (props: any) => {
  let retVal = null
  const [ user, claim, loading ] = useFirebaseAuth()
  const { Component, rest } = props
  if (!loading) {
    if (user) {
      if (claim.permission === 0) {
        retVal = <>
          <PageDrawer />
          <Component {...rest} />
        </>
      }
    } else {
      retVal = <Login />
    }
  }

  return retVal
}
