import React, { FunctionComponent } from "react"
import { CouponReserveDownload } from "./CouponReserve/couponReserveDownload"

export const CouponReserveDownloadPage: FunctionComponent = () => {
  return (
    <>
      <CouponReserveDownload />
    </>
  )
}
