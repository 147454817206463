export const localeJP = {
  mixed: {
    default: "${path}のフォーマットが不正です。",
    required: "${path}は必須項目です。",
    oneOf: "${path}には${values}のいずれかの値を設定して下さい。",
    notOneOf: "${path}は${values}以外を設定して下さい。"
  },
  string: {
    length: "${path}は${length}桁で設定して下さい。",
    min: "${path}は${min}桁以上を設定して下さい。",
    max: "${path}は${max}桁以内を設定して下さい。",
    matches: '${path}には右記のフォーマットを設定して下さい。 "${regex}"',
    email: "${path}に正しいEメールアドレスを設定して下さい。",
    url: "${path}に正しいURLを設定して下さい。",
    trim: "${path}に余計な空白は含めないで下さい。",
    lowercase: "${path}は全て小文字を設定して下さい。",
    uppercase: "${path}は全て大文字を設定して下さい。"
  },
  number: {
    min: "${path}は${min}以上を設定して下さい。",
    max: "${path}は${max}以下を設定して下さい。",
    lessThan: "${path}は${less}未満を設定して下さい",
    moreThan: "${path}は${more}より大きい値を設定して下さい。",
    notEqual: "${path}には${notEqual}以外を設定して下さい。",
    positive: "${path}は正の値を設定して下さい。",
    negative: "${path}は負の値を設定して下さい。",
    integer: "${path}には数値を設定して下さい。"
  },
  date: {
    min: "${path}は${min}以降の日付を設定して下さい。",
    max: "${path}は${max}以前の日付を設定して下さい。"
  },
  object: {
    noUnknown:
      "${path}には未定義のオブジェクトが設定されています。"
  },
  array: {
    min: "${path}には${min}以上の要素を設定して下さい。",
    max: "${path}には${max}以下の要素を設定して下さい。"
  }
};
