import React, { FunctionComponent, useCallback, useState } from "react"
import {
  Box, Button,
  Divider,
  Paper,
  Typography
} from "@material-ui/core"
import { useStyles } from "../styles/useStyle"
import CSVReader from "react-csv-reader"
import {
  IRegisterResponse,
} from "../utils/medicalDao"
import { userSchema } from "../utils/validate"
import { DialogWithLoading } from "./Common/dialogWithLoading"
import { CSVView } from "./Common/csvView"
import { Result } from "./Common/result"
import { convertUserCSVToJSON, convertUserJsonToFB, IUserCSV, registerUser, UserCSVHeader } from "../utils/userDao"
import { UserTemplateDownload } from "./User/templateDownload"
import { useFirebaseFunction } from "../utils/functions"
import { useFirestore } from "../utils/firestore"

export const UserPage: FunctionComponent = () => {
  const classes = useStyles()
  const functions = useFirebaseFunction()
  const firestore = useFirestore()
  const [ errors, setErrors ] = useState<string[]>([])
  const [ csvData, setCSVData ] = useState<IUserCSV[]>([])
  const [ result, setResult ] = useState<IRegisterResponse[]>([])
  const [ show, setShow ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  const renderResult = useCallback(() => {
    return <Result result={result} />
  }, [ result ])

  const renderCSVData = useCallback(() => {
    let retVal = null
    if (!result.length) {
      retVal = <CSVView header={UserCSVHeader} data={csvData} />
    }

    return retVal
  }, [ csvData, result ])

  const ok = useCallback(() => {
    setErrors([])
    setCSVData([])
    setResult([])
  }, [])

  const csvCancel = useCallback(() => {
    setErrors([])
    setCSVData([])
  }, [])

  const cancel = () => {
    setShow(false)
  }

  const confirm = () => {
    setShow(true)
  }

  const execute = async () => {
    setLoading(true)
    try {
      const promiseArray = csvData.map(data => convertUserJsonToFB(firestore, data))
      const data = await Promise.all(promiseArray)
      const result = await registerUser(firestore, functions, data)
      setResult(result)
    } catch (e) {
      console.log(e)
      setResult([ {
        index: 0,
        result: "不明なエラーが発生しました。ページを更新して最初からやり直してください"
      } ])
    } finally {
      setShow(false)
      setLoading(false)
    }
  }

  const renderControlButton = useCallback(() => {
    let retVal = null
    if (!result.length) {
      if (csvData.length) {
        retVal = (
          <Box className={classes.verticalMargin}>
            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className={classes.horizontalMargin} variant={"contained"} onClick={csvCancel}>キャンセル</Button>
              <Button variant={"contained"} color={"primary"} disabled={!!errors.length}
                      onClick={confirm}>取り込み実行</Button>
            </Box>
          </Box>
        )
      }
    } else {
      retVal = (
        <Box className={classes.verticalMargin}>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button className={classes.horizontalMargin} variant={"contained"} color={"primary"}
                    onClick={ok}>OK</Button>
          </Box>
        </Box>
      )
    }

    return retVal
  }, [ errors, csvData, result ])

  const renderErrorMessage = useCallback(() => {
    let retVal = null
    if (!result.length) {
      if (csvData.length) {
        let messages = null
        if (errors.length) {
          messages = errors.map((error, index) => <Typography key={`error_${index}`} variant={"body2"}
                                                              color={"error"}>{error}</Typography>)
        } else {
          messages = <Typography variant={"body2"} color={"primary"}>エラーはありませんでした</Typography>
        }
        retVal = (
          <Paper className={classes.paperContainer}>
            <Typography component={"h3"} variant={"h5"}>エラー情報</Typography>
            <Divider />
            <Box className={classes.wrapMargin}>
              {messages}
            </Box>
          </Paper>
        )
      }
    }

    return retVal
  }, [ csvData, errors, result ])

  const renderCSVImport = useCallback(() => {
    let retVal = null
    if (!result.length) {
      if (!csvData.length) {
        retVal = (
          <Paper className={classes.paperContainer}>
            <Typography component={"h3"} variant={"h5"}>医療従事者情報インポート</Typography>
            <Divider />
            <Box className={classes.wrapMargin}>
              <CSVReader
                onFileLoaded={(data, _) => {
                  const promiseArray: Promise<{
                    errors: string[],
                    result: any
                  }>[] = []
                  data.forEach((value, index) => {
                    if (index !== 0) {
                      promiseArray.push(new Promise((resolve) => {
                        let errors: any[] = []
                        let converted: any = null

                        try {
                          if (value.length === UserCSVHeader.length) {
                            converted = userSchema.cast(convertUserCSVToJSON(value))
                          } else {
                            errors.push(`${index + 1}行目：CSVが不正です。列数が一致していません`)
                          }
                        } catch (e) {
                          console.log(e)
                          errors.push(`${index + 1}行目：CSVが不正です`)
                        }
                        if (errors.length == 0) {
                          userSchema.validate(converted).then((result) => {
                            resolve({
                              errors,
                              result
                            })
                          }).catch(e => {
                            errors = e.errors.map((text: string) => `${index + 1}行目：${text}`)
                            resolve({
                              errors,
                              result: null
                            })
                          })
                        } else {
                          resolve({
                            errors,
                            result: null
                          })
                        }
                      }))
                    }
                  })

                  Promise.all(promiseArray).then(values => {
                    let errors: string[] = []
                    let result: any[] = []

                    values.forEach(value => {
                      if (value.errors.length) {
                        errors = errors.concat(value.errors)
                      } else {
                        result.push(value.result)
                      }
                    })
                    setCSVData(result)
                    setErrors(errors)
                  })
                }}
              />
            </Box>
          </Paper>
        )
      }
    }

    return retVal
  }, [ csvData, result ])

  return (
    <>
      <UserTemplateDownload />
      {renderCSVImport()}
      {renderErrorMessage()}
      {renderCSVData()}
      {renderResult()}
      {renderControlButton()}
      <DialogWithLoading
        title={"実行確認"}
        body={"CSVインポート処理を実行します。よろしいですか？"}
        show={show}
        loading={loading}
        onCancel={cancel}
        onExecute={execute}
      />
    </>
  )
}
